import * as React from "react";
import { useRive } from '@rive-app/react-canvas';
import wordAnim from "../images/rive/cwa_text_animation.riv";

export default function RiveAnim() {
  const { rive, RiveComponent } = useRive({
    src: wordAnim,
    // src: 'https://www.freshconsulting.com/wp-content/uploads/cwa_text_animation.riv',
    autoplay: true,
    artBoard: "V1",
    stateMachines: "State Machine 1",
    loop: true,
  });

  return (
    <RiveComponent
    />
  );
}
