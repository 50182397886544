import { hasNames } from "../utils";
import { StaticImage } from "gatsby-plugin-image";
import * as React from "react";
import { forwardRef } from "react";

import HeroOverlay from "../images/hero-overlay.inline.svg";

import * as styles from "../styles/hero.module.scss";

const StaticHero = forwardRef((props, ref) => {
	const { image = "", children } = props;

	const imageClassName = image
		.replace(/(.+)\.?/, "$1")
		.toLocaleLowerCase()
		.replace(/[^\w]+/, "-");
	return (
		<div ref={ref} className={hasNames(styles.hero, imageClassName)}>
			<HeroOverlay className={styles.overlay} />
			<StaticImage
				src='../images/heros/cwa-hero-home.jpg'
				alt=""
				loading="eager"
				aria-hidden="true"
				placeholder="blurred"
				layout="fullWidth"
				quality={85}
			/>
			<div className={styles.contentContainer}>
				<div className={styles.childrenContainer}>{children}</div>
			</div>
		</div>
	);
});

export default StaticHero;
