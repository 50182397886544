import { hasNames } from "../utils";
import * as React from "react";
import { useEffect } from "react";
import { useState } from "react";

import ChevronFilled from "../images/chevron-filled.icon.svg";

import * as styles from "../styles/interactive-scroll.module.scss";

const Dot = ({
	isActive = false,
	onClick,
	location,
	above = false,
	below = false,
}) => {
	return (
		<button className={styles.dotWrapper} onClick={onClick}>
			<div
				className={hasNames(
					styles.dot,
					isActive ? styles.active : styles.inactive
				)}
				aria-label={`Scroll ${below ? "up" : "down"} to ${location.label}`}>
				{below === true && <ChevronFilled />}
				{above === true && <ChevronFilled className={styles.rotated} />}
			</div>
			<div
				className={styles.tooltip}
				aria-label={`Label for ${location.label} scroll button`}>
				<div>{location.label}</div>
			</div>
		</button>
	);
};

const InteractiveScroll = ({ locations }) => {
	const [activeIdx, setActiveIdx] = useState(0);

	const onClick = (index, ref) => {
		if (ref && ref.current) {
			setActiveIdx(index);
			ref.current.scrollIntoView({
				behavior: "smooth",
			});
		} else {
			console.error("ref is undefined");
		}
	};

	useEffect(() => {
		const handleScroll = (event) => {
			locations.forEach((location, i) => {
				if (location.ref && location.ref.current !== undefined) {
					const top = location?.ref?.current?.getBoundingClientRect()?.top;

					if (top <= 300 && top >= -300) {
						setActiveIdx(i);
					}
				}
			});
		};

		window.addEventListener("scroll", handleScroll, { passive: true });
		return () => {
			window.removeEventListener("scroll", handleScroll);
		};
	});

	return (
		<div
			className={styles.scrollContainer}
			aria-label="Quick scroll component"
			role="navigation">
			{locations.map((location, idx) => {
				return (
					<Dot
						key={`${location.label}${idx}`}
						location={location}
						isActive={activeIdx === idx}
						onClick={() => onClick(idx, location.ref)}
						above={idx < activeIdx ? true : false}
						below={idx > activeIdx ? true : false}
					/>
				);
			})}
		</div>
	);
};

export default InteractiveScroll;
