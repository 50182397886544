import { hasNames } from "../utils";
import * as React from "react";
import { Children } from "react";

import * as styles from "../styles/fifty-fifty.module.scss";

const FiftyFifty = ({
	children,
	reverseMobile = false,
	className = "",
	useRef = null,
}) => {
	const childrenCount = Children.count(children);
	return (
		<div
			ref={useRef}
			className={hasNames(
				styles.FiftyFifty,
				reverseMobile ? styles.reverseMobile : null,
				className
			)}>
			{childrenCount === 2 ? (
				children
			) : (
				<div className={styles.componentError}>
					The FiftyFifty component must have exactly 2 children but you provided{" "}
					{childrenCount}.
				</div>
			)}
		</div>
	);
};

export default FiftyFifty;
