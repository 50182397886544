import { graphql } from "gatsby";
import { StaticImage } from "gatsby-plugin-image";
import { useRef } from "react";
import * as React from "react";

import Button from "../components/button";
import ContactUsFooter from "../components/contact-us-footer";
import FiftyFifty from "../components/fifty-fifty";
import Frame from "../components/frame";
import { HeadMeta } from "../components/head-meta";
import InteractiveScroll from "../components/interactive-scroll";
import FullWidthCta from "../components/full-width-cta";

import RiveAnim from "../components/rive";

import * as com from "../styles/common.module.scss";
import * as styles from "../styles/index.module.scss";
import * as typ from "../styles/typography.module.scss";
import * as contactFooter from "../styles/contact-us-footer.module.scss";
import StaticHero from "../components/static-hero";

export const Head = () => {
	return <HeadMeta title="Integrated Wealth Management" />;
};

const IndexPage = ({ data }) => {
	const approachRef = useRef(null);
	const teamRef = useRef(null);
	const storyRef = useRef(null);
	const contactRef = useRef(null);

	const scrollLocations = [
		{ label: "Our Approach", ref: approachRef },
		{ label: "Our Team", ref: teamRef },
		{ label: "Cynosure Story", ref: storyRef },
		{ label: "Contact", ref: contactRef },
	];

	return (
		<Frame>
			<InteractiveScroll locations={scrollLocations} />

			<StaticHero image="Meeting">
			<h1>White-glove service from seasoned advisors</h1>
			<p>
				At Cynosure Wealth Advisors, we understand the opportunities and complexities that
				come with significant wealth. Our team has the breadth and depth of experience to
				truly serve the needs of ultra-high-net-worth families, entrepreneurs, and senior
				executives.
				</p>
			</StaticHero>

			{/* compensate for scroll bar width of 15px */}
			<div className={styles.riveContainer}>
					<RiveAnim />
				</div>

				<FiftyFifty reverseMobile={false} useRef={approachRef}>
					<StaticImage
						src="../images/cwa-approach.jpg"
						alt="First Security Building"
						aspectRatio={1 / 1}
						layout="constrained"
						width={640}
						height={640}
						quality={85}
					/>
					<div className="extra-gap">
						<div className={typ.eyebrow}>
						our approach
						</div>
						<h2 className={com.browedHeader}>
						A Customized Approach for Clarity and Confidence
						</h2>
						<div className={typ.bodyLargeLight}>
						<p>Our team helps you make informed decisions about your wealth. With our open architecture investment platform and fee-only compensation model, we ensure that your investment strategies and planning opportunities align with your goals and needs. No matter the magnitude of your wealth, we can help you leverage the same investment insight, experience, and access to investment solutions that have been made available by our founding family and to our institutional investors. Find out how our customized approach benefits you.</p>
						</div>
						<Button to="/approach" type="secondary" className={com.leftAlignedButton}>
							Learn How
						</Button>
					</div>
				</FiftyFifty>

				<FullWidthCta ref={teamRef} />

				<FiftyFifty reverseMobile={true} useRef={storyRef}>
					<StaticImage
						src="../images/cwa-cynosure-story.jpg"
						alt="First Security Building"
						aspectRatio={1 / 1}
						layout="constrained"
						width={640}
						height={640}
						quality={85}
						className={com.imageClippedLeft}
					/>
					<div className="extra-gap">
						<div className={typ.eyebrow}>
						the cynosure story
						</div>
						<h2 className={com.browedHeader}>
						Our long history. Your bright future.
						</h2>
						<div className={typ.bodyLargeLight}>
						<p>In 2014, The Cynosure Group was founded to support the private investments and portfolio management for one of the American West's most influential families, the Eccles.</p>
						<p>With unique insights into the challenges faced by wealthy individuals and families, Cynosure helps clients reduce complexity while maximizing the effectiveness of investment choices.</p>
						</div>
						<Button to="/our-history/" type="secondary" className={com.leftAlignedButton}>
							Find out More
						</Button>
					</div>
				</FiftyFifty>

				<ContactUsFooter
				ref={contactRef}
				backdrop={
					<StaticImage
						className={contactFooter.image}
						src={`../images/cwa-footer-1.png`}
						alt=""
						aria-hidden="true"
						layout="fullWidth"
						quality={85}
					/>
				}/>
		</Frame>
	);
};

export default IndexPage;

export const query = graphql`
	query HomePageQuery {
		newsArticles: allMarkdownRemark(
			filter: { fileAbsolutePath: { regex: "/src/cms/data/news/" } }
			sort: { fields: frontmatter___date, order: DESC }
			limit: 3
		) {
			nodes {
				id
				frontmatter {
					title
					date(formatString: "MMMM Do YYYY")
					tags
				}
				fields {
					url
				}
				excerpt(pruneLength: 128)
			}
		}
	}
`;
