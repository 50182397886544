// extracted by mini-css-extract-plugin
export var FiftyFifty = "fifty-fifty-module--FiftyFifty--cf1c1";
export var col0 = "fifty-fifty-module--col0--285e0";
export var col1 = "fifty-fifty-module--col1--bb103";
export var col10 = "fifty-fifty-module--col10--131da";
export var col11 = "fifty-fifty-module--col11--3394a";
export var col12 = "fifty-fifty-module--col12--1c80b";
export var col2 = "fifty-fifty-module--col2--0ab47";
export var col3 = "fifty-fifty-module--col3--e975b";
export var col4 = "fifty-fifty-module--col4--6d4c8";
export var col5 = "fifty-fifty-module--col5--2ae75";
export var col6 = "fifty-fifty-module--col6--7b7fe";
export var col7 = "fifty-fifty-module--col7--0b002";
export var col8 = "fifty-fifty-module--col8--0b27c";
export var col9 = "fifty-fifty-module--col9--015c2";
export var colLg0 = "fifty-fifty-module--colLg0--cc91c";
export var colLg1 = "fifty-fifty-module--colLg1--48879";
export var colLg10 = "fifty-fifty-module--colLg10--d72c5";
export var colLg11 = "fifty-fifty-module--colLg11--f2501";
export var colLg12 = "fifty-fifty-module--colLg12--50b19";
export var colLg2 = "fifty-fifty-module--colLg2--5a16b";
export var colLg3 = "fifty-fifty-module--colLg3--10552";
export var colLg4 = "fifty-fifty-module--colLg4--3121b";
export var colLg5 = "fifty-fifty-module--colLg5--cbd7d";
export var colLg6 = "fifty-fifty-module--colLg6--b8871";
export var colLg7 = "fifty-fifty-module--colLg7--20706";
export var colLg8 = "fifty-fifty-module--colLg8--edb9e";
export var colLg9 = "fifty-fifty-module--colLg9--abb81";
export var colMd0 = "fifty-fifty-module--colMd0--40bae";
export var colMd1 = "fifty-fifty-module--colMd1--98ca3";
export var colMd10 = "fifty-fifty-module--colMd10--24709";
export var colMd11 = "fifty-fifty-module--colMd11--af86d";
export var colMd12 = "fifty-fifty-module--colMd12--856fb";
export var colMd2 = "fifty-fifty-module--colMd2--7f3a5";
export var colMd3 = "fifty-fifty-module--colMd3--8d58b";
export var colMd4 = "fifty-fifty-module--colMd4--34626";
export var colMd5 = "fifty-fifty-module--colMd5--c5b8e";
export var colMd6 = "fifty-fifty-module--colMd6--7d964";
export var colMd7 = "fifty-fifty-module--colMd7--22a10";
export var colMd8 = "fifty-fifty-module--colMd8--1a4d7";
export var colMd9 = "fifty-fifty-module--colMd9--d3d1c";
export var colSm0 = "fifty-fifty-module--colSm0--6c7d2";
export var colSm1 = "fifty-fifty-module--colSm1--12a6b";
export var colSm10 = "fifty-fifty-module--colSm10--3ad3c";
export var colSm11 = "fifty-fifty-module--colSm11--bf880";
export var colSm12 = "fifty-fifty-module--colSm12--44801";
export var colSm2 = "fifty-fifty-module--colSm2--516d4";
export var colSm3 = "fifty-fifty-module--colSm3--5a0cf";
export var colSm4 = "fifty-fifty-module--colSm4--4f930";
export var colSm5 = "fifty-fifty-module--colSm5--13aac";
export var colSm6 = "fifty-fifty-module--colSm6--08880";
export var colSm7 = "fifty-fifty-module--colSm7--04c76";
export var colSm8 = "fifty-fifty-module--colSm8--8dd89";
export var colSm9 = "fifty-fifty-module--colSm9--d638a";
export var componentError = "fifty-fifty-module--componentError--ffd2e";
export var container = "fifty-fifty-module--container--a6349";
export var gridContainer = "fifty-fifty-module--gridContainer--c29b2";
export var reverseMobile = "fifty-fifty-module--reverseMobile--f3190";