import { hasNames } from "../utils";
import * as React from "react";
import { StaticImage } from "gatsby-plugin-image";
import { forwardRef } from "react";

import Button from "../components/button";

import * as styles from "../styles/full-width-cta.module.scss";
import * as com from "../styles/common.module.scss";
import * as grd from "../styles/grid.module.scss";
import * as typ from "../styles/typography.module.scss";

const FullWidthCta = forwardRef((props, ref) => {
	return (
		<div className={hasNames(styles.FullWidthCta)} ref={ref}>
			<StaticImage
				src={`../images/our-team-section.jpg`}
				alt="our team"
				loading="eager"
				aria-hidden="true"
				placeholder="blurred"
				layout="fullWidth"
				quality={85}
			/>
			<div className={hasNames(styles.FullWidthCtaWrapper, grd.gridContainer)}>
				<div className={hasNames(grd.col6, grd.colMd12)}>
					<div className={typ.eyebrow}>
					the origin story
					</div>
					<h2 className={com.browedHeader}>
					A modern investment approach based on time -tested principles 
					</h2>
					<div className={typ.bodyLargeLight}>
					<p>At Cynosure, we draw on the insights and capabilities of our multifaceted team, as well as our full range of investment resources, to create tailored solutions for each client. With our integrated approach, you will benefit from a single point of contact who understands your investment philosophy - as well as access to a broader team of legal, tax, and insurance experts.</p>
					</div>
					<Button to="/team" type="primary" className={com.leftAlignedButton}>
					Meet the Team
					</Button>
				</div>
			</div>
		</div>
	);
});

export default FullWidthCta;
